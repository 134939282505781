import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContactForm from '../components/ContactForm'
import AdvancedSettings from "../settings/advanced_settings.json";
import Img from "gatsby-image";
import StarsRatingComponent from "../components/StarsRatingsComponent";
import HowItWorks from "../components/HowItWorks";
import AgentvsSellFastSactable from '../components/AgentvsSellFastSacTable'
import { CameraIcon } from '@heroicons/react/solid'
import SeeTheDifferenceSidebar from '../components/SeeTheDifferenceSidebar'

const SellYourHouse = ({ data }) => {
  const { headerImage, teamImage } = data;

  return (
    <Layout headerTransparent={false}>
      <SEO
        title={'Sell Your House in Sacramento | Direct Offer | 916-237-9457'}
        description={'Do you need to sell your house in Sacramento? We will make it easy for you! Reach out to us today to learn more about us and what we can offer you!'}
        keywords={''}
      />
  


   


      <section className="py-16 section-stripe-bg bg-gray-50 ">
        <div className="flex flex-wrap px-6 md:px-8 container mx-auto ">
          <div className="w-full md:w-2/3 md:pr-4 prose">
             <h1 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl mt-2 mb-2">Sell Your House Fast in Sacramento!</h1>



           
<p><em>Why do you need to sell your house in Sacramento? </em></p>



<ul><li><span>Do you own a vacant property?</span></li><li>Are you worried about foreclosure?</li><li>Have you inherited a house you don’t want?</li><li>Does the property have code violations?</li><li>Do you want to sell fast to avoid holding costs?</li><li>Are there tenants currently living in the home?</li><li>Do you want to sell without having to clean, stage, or show the property?</li><li>Do you want to sell your house without any expensive agent commissions?</li></ul>



<p>No matter what you are facing, we are ready to help you! We will help you find the best solution for your situation, without any pressure to work together. If you opt to sell directly, we will handle all of the details and close in just a few short days. </p>



<p>Of course, the actual closing date is completely up to you. We only need about 7 days to get everything ready. </p>



<h4><em>The Easiest Way To Sell Your House In Sacramento…</em></h4>



<p>At Sell Your Home For Cash Sacramento, our goal is to make the selling process as simple as possible for you. We will make you a fair, straightforward offer, closing whenever you are ready. <span>You will not have to worry about inspections, appraisals, repairs, or waiting around to find a buyer. We will make you an offer and close as quickly as you need us to. There are no costs, commissions, or hidden fees. </span>Fill out the form below to send us a message or give us a call today to learn more about us and what we can do for you! <strong>916-237-9457 </strong></p>

          </div>
          <div className="w-full md:w-1/3 md:pl-4">


          <SeeTheDifferenceSidebar/>
            <div className="rounded-xl mb-2 shadow bg-white  mt-6 md:mt-0">
              <div className="px-8 pt-8">
                <h2 className="text-gray-800 font-bold text-2xl md:text-3xl leading-8">Get Your Fair Cash Offer: Start Below!</h2>
                <p class="text-gray-600 mb-2 leading-6 mt-1">We buy houses in any condition -- no commissions or fees and no obligation whatsoever. Start below by giving us a bit of information about your property or call 916-237-9457.</p>
              </div>


              <div className="p-8 pt-4"><ContactForm stacked={true} hideLabels={true} showPlaceholders={true} /></div>

            </div>
          
          </div>
        </div>
      </section>
 

      <HowItWorks />

<AgentvsSellFastSactable />
    </Layout>
  );
};
 

export default SellYourHouse;

export const pageQuery = graphql`
  query SellYourHouseQuery {
 
    headerImage: file(relativePath: { eq: "home.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
          src
        }
      }
    }
    teamImage: file(relativePath: { eq: "team-pic.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
          src
        }
      }
    }
  } 
`;
